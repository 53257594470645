import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import CircularProgress from "@mui/material/CircularProgress";
import DataTableToolbar from "./DataTableToolbar";
import DataTableHead from "./DataTableHead";
import moment from "../../helpers/moment";
import { useCounterpart } from "../../context/language.context";
import CustomAvatar from "../CustomAvatar";
import { ROLELABELS } from "../../helpers/role";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const DataTable = ({
  headCells,
  rows,
  onRowSelected,
  showCheck,
  onButtonClicked,
  isInitSelected,
  pageInfo,
  handleChangePage,
  handleDetail,
  handleToggle,
  loadingToggle,
}) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const { locale } = useCounterpart();
  //   const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (e) => {
    if (e.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (e, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  // const handleChangePage = (e, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (e) => {
  //   setRowsPerPage(parseInt(e.target.value, 10));
  //   setPage(0);
  // };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  useEffect(() => {
    if (isInitSelected) {
      setSelected([]);
    }
  }, [isInitSelected, rows]);

  const renderRow = (head, row) => {
    if (head.isDate) {
      return (
        locale &&
        row[head.id] &&
        moment(new Date(row[head.id])).locale(locale).format("DD MMM YYYY")
      );
    }

    if (head.isRole) {
      return ROLELABELS[row[head.id]];
    }

    if (head?.maxLength) {
      return row[head.id]?.length > head?.maxLength
        ? `${row[head.id]?.substring(0, head.maxLength)}...`
        : row[head.id];
    }

    return row[head.id];
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <DataTableToolbar
          selected={selected}
          onButtonClicked={onButtonClicked}
          //   setIsRemoveModalOpen={setIsRemoveModalOpen}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <DataTableHead
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
              showCheck={showCheck}
              showAction={!!handleDetail}
            />
            <TableBody>
              {rows &&
                stableSort(rows, getComparator(order, orderBy))
                  // .slice(
                  //   pageInfo.currentPage * pageInfo.pageSize,
                  //   pageInfo.currentPage * pageInfo.pageSize + pageInfo.pageSize
                  // )
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        {showCheck && (
                          <TableCell
                            padding="checkbox"
                            onClick={(e) => handleClick(e, row.id)}
                            sx={{ padding: "2px", paddingLeft: "4px" }}
                          >
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                        )}
                        {headCells.map((head) => {
                          if (head.isAvatar) {
                            return (
                              <TableCell
                                key={head.id}
                                align="center"
                                sx={{
                                  padding: "2px",
                                  textAlign: "-webkit-center",
                                }}
                              >
                                <CustomAvatar user={row} size="sm" />
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell
                              key={head.id}
                              onClick={
                                onRowSelected ? () => onRowSelected(row) : null
                              }
                              align="center"
                              sx={{
                                cursor: onRowSelected ? "pointer" : "default",
                                color: row.deletionDate && "grey !important",
                              }}
                            >
                              {renderRow(head, row)}
                            </TableCell>
                          );
                        })}
                        {(handleDetail || handleToggle) && (
                          <TableCell>
                            <Box display="flex" alignItems="center" gap="10px">
                              {handleToggle && (
                                <Box>
                                  {loadingToggle?.loading &&
                                  loadingToggle?.idSelected === row.id ? (
                                    <CircularProgress
                                      sx={{
                                        width: "30px !important",
                                        height: "30px !important",
                                      }}
                                    />
                                  ) : (
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          checked={Boolean(row?.processingDate)}
                                          onChange={(e) =>
                                            handleToggle(e, row.id)
                                          }
                                        />
                                      }
                                      label="Traiter"
                                    />
                                  )}
                                </Box>
                              )}
                              {handleDetail && (
                                <Button
                                  variant="contained"
                                  onClick={() => handleDetail(row)}
                                >
                                  <VisibilityIcon sx={{ mr: 1 }} />
                                  Voir détail
                                </Button>
                              )}
                            </Box>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
              {/* {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={5} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        {pageInfo && (
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={pageInfo.total}
            rowsPerPage={pageInfo.pageSize}
            page={pageInfo.currentPage - 1}
            onPageChange={handleChangePage}
            // onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
      {/* {isRemoveModalOpen && (
        <ConfirmDialog
          title={counterpart("event.remove-participant")}
          confirmText={counterpart("event.confirm-remove-participant")}
          toggleRemoveModal={toggleRemoveModal}
          isOpen={isRemoveModalOpen}
          handleRemove={handleRemove}
        />
      )} */}
    </Box>
  );
};

DataTable.propTypes = {
  headCells: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onRowSelected: PropTypes.func,
  showCheck: PropTypes.bool,
  onButtonClicked: PropTypes.func,
  isInitSelected: PropTypes.bool,
  pageInfo: PropTypes.shape(),
  handleChangePage: PropTypes.func,
  handleDetail: PropTypes.func,
  handleToggle: PropTypes.func,
  loadingToggle: PropTypes.shape(),
};

DataTable.defaultProps = {
  onRowSelected: null,
  showCheck: false,
  onButtonClicked: null,
  isInitSelected: false,
  pageInfo: null,
  handleChangePage: null,
  handleDetail: null,
  handleToggle: null,
  loadingToggle: null,
};

export default DataTable;
