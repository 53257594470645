import { USER } from "../constants/actions";

/* eslint-disable default-param-last */
const initialState = null;

export function user(state = initialState, action) {
  switch (action.type) {
    case USER.GET_ALL:
      return action.payload;
    case USER.UPDATE_USER:
      if (state && action.payload) {
        const usersClone = [...state];
        action.payload.userIds.forEach((userId) => {
          const index = usersClone.findIndex(
            (userItem) => userItem.id === userId
          );
          if (action.payload.activate) {
            usersClone[index].deletionDate = null;
          } else {
            usersClone[index].deletionDate = "isDisabled";
          }
        });
        return usersClone;
      }
      return state;
    default:
      return state;
  }
}
