/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useMemo } from "react";
// import Image from "next/image";
// import Link from "next/link";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
// import { useRouter } from "next/router";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import Grow from "@mui/material/Grow";
import { useHistory } from "react-router";
import CustomContainer from "../CustomContainer";
import { ROUTES } from "../../constants/routes";
import {
  ContentLogo,
  ContentMenu,
  CustomAppBar,
  CustomMenuItem,
  CustomSelect,
  CustomToolbar,
} from "./style";
import { useCounterpart } from "../../context/language.context";
import en from "../../assets/flags/en.png";
import fr from "../../assets/flags/fr.png";
import logoFr from "../../assets/logo/logo-fr.svg";
import logoEn from "../../assets/logo/logo-en.svg";
// import { updateLanguage } from "../../actions/session.actions";
import NavBarMenu from "../NavBarMenu";
import AvatarMenu from "../AvatarMenu";
import { getCookie } from "../../helpers/cookie";

const NavBar = ({ setMenuOpen, user }) => {
  const { handleSetLocale, counterpart, locale } = useCounterpart();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const isMd = useMediaQuery("(min-width: 900px)");

  const handleChangeLang = (event) => {
    const { value } = event.target;
    handleSetLocale(value);
    if (user) {
      //   dispatch(updateLanguage({ language: value }));
    }
  };
  const pages = useMemo(
    () => [
      {
        label: counterpart("menus.users"),
        route: ROUTES.USERS,
        child: [],
      },
      {
        label: counterpart("menus.mediators"),
        route: "",
        child: [
          {
            label: counterpart("menus.validationApply"),
            route: ROUTES.APPLICATION_VALIDATION,
          },
          {
            label: counterpart("menus.validationMediator"),
            route: ROUTES.MEDIATORS,
          },
        ],
      },
      {
        label: counterpart("menus.advice"),
        route: "",
        child: [
          {
            label: counterpart("menus.withoutPrescription"),
            route: ROUTES.ADVICE,
          },
          {
            label: counterpart("menus.WithPerscription"),
            route: ROUTES.PRESCRIPTION,
          },
        ],
      },
    ],
    [locale]
  );

  return (
    <CustomAppBar position="sticky">
      <CustomContainer paddingX={1} paddingY={0}>
        <CustomToolbar>
          <Grow in timeout={500}>
            <ContentLogo>
              {/* <Link href={ROUTES.ROOT}> */}
              {/* <a>
                <Logo color="white" />
              </a> */}
              <a
                href={`${process.env.REACT_APP_FRONT}/${getCookie(
                  "NEXT_LOCALE"
                )}`}
              >
                <img
                  src={locale === "fr" ? logoFr : logoEn}
                  alt="Jurismediation"
                  width={226}
                  height={42}
                />
              </a>
              {/* </Link> */}
              <ContentMenu>
                {pages.map((page) => (
                  <NavBarMenu
                    key={page.label}
                    menu={{
                      label: page.label,
                      route: page.route,
                    }}
                    childMenu={page.child}
                  />
                ))}
              </ContentMenu>
            </ContentLogo>
          </Grow>
          {locale && (
            <CustomSelect
              labelId="simple-select-standard-label"
              id="simple-select-standard"
              value={locale}
              onChange={handleChangeLang}
              variant="outlined"
              sx={{ height: "15px", padding: "0px" }}
            >
              <CustomMenuItem value="fr">
                <img src={fr} alt="fr" width={25} />
              </CustomMenuItem>
              <CustomMenuItem value="en">
                <img src={en} alt="en" width={25} />
              </CustomMenuItem>
            </CustomSelect>
          )}
          {!isMd && (
            <IconButton
              size="small"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={setMenuOpen}
              sx={{
                border: "2.5px solid white",
                borderRadius: "4px",
              }}
            >
              <MenuIcon
                className="burger"
                sx={{
                  fontSize: "16px!important",
                }}
              />
            </IconButton>
          )}
          {isMd && user && <AvatarMenu user={user} />}
          {isMd && !user && (
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => {
                history.replace(ROUTES.LOGIN);
              }}
            >
              {counterpart("menus.to-login")}
            </Button>
          )}
        </CustomToolbar>
      </CustomContainer>
    </CustomAppBar>
  );
};

NavBar.propTypes = {
  setMenuOpen: PropTypes.func.isRequired,
  user: PropTypes.shape(),
};

NavBar.defaultProps = {
  user: null,
};

export default NavBar;
