/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import { getCookie } from "../helpers/cookie";
import { sessionService } from "../services/session.service";
import { setToken } from "../helpers/utils";
import { ROLES } from "../helpers/role";

const SessionContext = createContext({
  user: PropTypes.shape().isRequired,
  isLoading: PropTypes.bool.isRequired,
});

export const SessionProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const value = useMemo(
    () => ({
      user,
      isLoading,
    }),
    [user, isLoading]
  );

  useEffect(() => {
    if (getCookie("token")) {
      const token = getCookie("token");
      sessionService
        .get(token)
        .then((res) => {
          if (res?.responseData?.role === ROLES.ADMIN) {
            setToken(token);
            setUser(res?.responseData);
            setIsLoading(false);
          } else {
            window.location.href = process.env.REACT_APP_FRONT;
            return null;
          }
        })
        .catch((err) => {
          window.location.href = process.env.REACT_APP_FRONT;
          return null;
        });
    } else {
      window.location.href = process.env.REACT_APP_FRONT;
      return null;
    }
  }, []);

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
};

SessionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useSession = () => useContext(SessionContext);
