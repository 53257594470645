/* eslint-disable arrow-body-style */
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import TextField from "@mui/material/TextField";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { useMemo } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useCounterpart } from "../../context/language.context";

const Filters = ({
  filters,
  handleSearch,
  handleRemoveFilter,
  showDate,
  showProcessed,
}) => {
  const { counterpart } = useCounterpart();

  const nbFilters = useMemo(() => {
    let value = 0;
    Object.keys(filters).forEach((item) => {
      if (filters[item] || filters[item]?.length > 0) {
        value += 1;
      }
    });
    return value;
  }, [filters]);

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box display="flex" alignItems="center" gap="5px">
          <Badge badgeContent={nbFilters} color="success">
            <FilterListIcon />
          </Badge>
          <Typography>{counterpart("common.filters")}</Typography>
          {nbFilters > 0 && (
            <IconButton onClick={handleRemoveFilter}>
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              name="search"
              label={counterpart("common.search")}
              value={filters.search}
              onChange={handleSearch}
              fullWidth
            />
          </Grid>
          {showProcessed && (
            <Grid item xs={12} md={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filters?.processed}
                    onChange={handleSearch}
                  />
                }
                label={counterpart("common.processed")}
              />
            </Grid>
          )}
          {showDate && (
            <>
              <Grid item xs={12} md={4}>
                <TextField
                  name="dateRangeStart"
                  label={counterpart("common.startDate")}
                  value={filters.dateRangeStart}
                  onChange={handleSearch}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  name="dateRangeEnd"
                  label={counterpart("common.endDate")}
                  value={filters.dateRangeEnd}
                  onChange={handleSearch}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Grid>
            </>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

Filters.propTypes = {
  filters: PropTypes.shape().isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleRemoveFilter: PropTypes.func.isRequired,
  showDate: PropTypes.bool,
  showProcessed: PropTypes.bool,
};

Filters.defaultProps = {
  showDate: false,
  showProcessed: false,
};
export default Filters;
