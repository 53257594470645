/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from "react";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import moment from "moment";
import Box from "@mui/material/Box";
import TableHead from "@mui/material/TableHead";
import CustomContainer from "../CustomContainer";
import Header from "../Header";
import { useCounterpart } from "../../context/language.context";
import { mediatorService } from "../../services/mediator.service";
import { ROLELABELS } from "../../helpers/role";
import ButtonAction from "./ButtonAction";
import { getCookie } from "../../helpers/cookie";
import { ROUTES } from "../../constants/routes";
import Filters from "../Filters";
import { initParams, updateQueryParams } from "../../helpers/utils";

const MediatorPage = () => {
  const [mediators, setMediators] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState();
  const { counterpart } = useCounterpart();
  const [filters, setFilters] = useState({
    search: "",
    dateRangeStart: "",
    dateRangeEnd: "",
  });

  const handleGetAllMediatorsToValidate = (
    pageValue,
    pageSizeValue,
    filtersValue
  ) => {
    mediatorService
      .getAllMediatorsToValidate(
        { page: pageValue, pageSize: pageSizeValue },
        filtersValue
      )
      .then((res) => {
        setMediators(res?.responseData?.hits);
        setPageCount(res?.responseData?.pageCount);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(Number(e.target.value));
    setPage(0);
  };

  const handleViewProfile = (slug) => {
    const newWindow = window.open(
      `${process.env.REACT_APP_FRONT}/${getCookie("NEXT_LOCALE") || "fr"}/${
        ROUTES.MEDIATOR?.[getCookie("NEXT_LOCALE") || "fr"]
      }/${slug}`,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  const handleSearch = (e) => {
    const filtersValue = e
      ? {
          ...filters,
          [e?.target?.name]: e?.target?.value || "",
        }
      : { search: "", dateRangeStart: "", dateRangeEnd: "" };
    setFilters(filtersValue);
    setTimeout(() => {
      handleGetAllMediatorsToValidate(1, rowsPerPage, filtersValue);
      updateQueryParams(filtersValue);
    }, 500);
  };

  const handleRemoveFilter = () => {
    handleSearch();
    initParams();
  };

  useEffect(() => {
    handleGetAllMediatorsToValidate(page, rowsPerPage, filters);
  }, [page, rowsPerPage]);

  return (
    <CustomContainer>
      <Header title={counterpart("menus.validationMediator")} />
      <Box mt={2} mb={2}>
        <Filters
          filters={filters}
          handleSearch={handleSearch}
          handleRemoveFilter={handleRemoveFilter}
          showDate
        />
      </Box>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableCell>{counterpart("common.firstname")}</TableCell>
              <TableCell>{counterpart("common.lastname")}</TableCell>
              <TableCell>{counterpart("common.email")}</TableCell>
              <TableCell>{counterpart("common.modification-date")}</TableCell>
              <TableCell>{counterpart("common.role")}</TableCell>
              <TableCell>Actions</TableCell>
            </TableHead>
            <TableBody>
              {mediators?.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item?.firstName}</TableCell>
                  <TableCell>{item?.lastName}</TableCell>
                  <TableCell>{item?.email}</TableCell>
                  <TableCell>
                    {item?.modificationDate
                      ? moment(item.modificationDate).format("DD MMM YYYY")
                      : null}
                  </TableCell>
                  <TableCell>{ROLELABELS[item?.role]}</TableCell>
                  <TableCell>
                    <ButtonAction
                      mediator={item}
                      handleViewProfile={handleViewProfile}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={pageCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </CustomContainer>
  );
};
export default MediatorPage;
