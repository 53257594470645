/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useCallback, useContext, useState } from "react";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const SnackbarContext = createContext({
  handleSetSnackbar: PropTypes.func.isRequired,
});

export const SnackbarProvider = ({ children }) => {
  const [snackbarState, setSnackbarState] = useState({
    isOpen: false,
    message: "",
    variant: "success",
  });

  const handleClose = () => {
    setSnackbarState({ ...snackbarState, isOpen: false });
  };

  const handleSetSnackbar = useCallback((value) => {
    setSnackbarState(value);
  }, []);

  return (
    <SnackbarContext.Provider value={{ handleSetSnackbar }}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarState.isOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={snackbarState.variant}>
          {snackbarState.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

SnackbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useSnackbar = () => useContext(SnackbarContext);
