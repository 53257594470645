import React from "react";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { HeaderContainer } from "./style";

const Header = ({ title }) => (
  <HeaderContainer>
    <Typography
      variant="h6"
      color="text.primary"
      sx={{
        fontWeight: 500,
      }}
    >
      {title}
    </Typography>
  </HeaderContainer>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
};
export default Header;
