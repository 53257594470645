export const ROUTES = {
  ROOT: "/",
  USERS: "/users",
  NEWSLETTER: "/newsletter",
  MEDIATORS: "/mediators",
  APPLICATION_VALIDATION: "/mediators/application-validation",
  LOGOUT: "/logout",
  LOGIN: "/login",
  ADVICE: "/advice",
  PRESCRIPTION: "/prescription",
  MEDIATOR: { en: "/mediator", fr: "/mediateur" },
};
