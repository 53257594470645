/* eslint-disable arrow-body-style */
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useCounterpart } from "../../../context/language.context";

const ApplicationDetail = ({ open, handleClose, item }) => {
  const { counterpart } = useCounterpart();

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Information</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Typography fontWeight={600}>
              {counterpart("form.name")}:
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography>{item?.user?.lastName}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography fontWeight={600}>
              {counterpart("form.firstname")}:
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography>{item?.user?.firstName}</Typography>
          </Grid>
          {/* <Grid item xs={12} md={4}>
            <Typography fontWeight={600}>
              {counterpart("form.address")}:
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
          <Typography>{item?.user?.firstName}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography fontWeight={600}>
              {counterpart("form.code")}:
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography>test</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography fontWeight={600}>
              {counterpart("form.city")}:
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography>test</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography fontWeight={600}>
              {counterpart("form.country")}:
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography>test</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography fontWeight={600}>
              {counterpart("form.phone")}:
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography>test</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography fontWeight={600}>
              {counterpart("form.email")}:
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography>test</Typography>
          </Grid> */}
          <Grid item xs={12} md={4}>
            <Typography fontWeight={600}>
              {counterpart("form.formation-pro")}:
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography>{item?.mediationHoursCount}h</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography fontWeight={600}>
              {counterpart("form.meditation-pro")}:
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography>{item?.trainingHoursCount}h</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          {counterpart("common.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ApplicationDetail.propTypes = {
  item: PropTypes.shape().isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ApplicationDetail;
