/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useCounterpart } from "../../context/language.context";
import Header from "../Header";
import CustomContainer from "../CustomContainer";
// import { userService } from "../../services/user.service";
import moment from "../../helpers/moment";
import { userService } from "../../services/user.service";
import { subscriptionService } from "../../services/subscription.service";
import ConfirmDialog from "../ConfirmDialog";
import ButtonAction from "./ButtonAction";
import Filters from "../Filters";
import { initParams, updateQueryParams } from "../../helpers/utils";
import ApplicationDetail from "./ApplicationDetail";
import { useSnackbar } from "../../context/snackbar.context";

const ApplicationPage = () => {
  const [applications, setApplications] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState();
  const [userSelected, setUserSelected] = useState();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [itemSelected, setItemSelected] = useState();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    search: "",
    dateRangeStart: "",
    dateRangeEnd: "",
  });
  const { counterpart } = useCounterpart();
  const { handleSetSnackbar } = useSnackbar();

  const handleGetApplication = (pageValue, pageSizeValue, filtersValue) => {
    userService
      .getUserApplied(
        { page: pageValue, pageSize: pageSizeValue },
        filtersValue
      )
      .then((res) => {
        setApplications(res?.responseData?.hits);
        setPageCount(res?.responseData?.pageCount);
      });
  };

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(Number(e.target.value));
    setPage(0);
  };

  const handleOpenConfirm = (userId) => {
    setUserSelected(userId);
    setOpenConfirm(true);
  };

  const handleRejectCandidate = () => {
    setLoading(true);
    subscriptionService
      .rejectCandidate({ userId: userSelected })
      .then(() => {
        const res = applications.filter(
          (item) => item?.user?.id !== userSelected
        );
        setApplications(res);
        setOpenConfirm(false);
      })
      .catch((err) => {
        handleSetSnackbar({
          isOpen: true,
          message: counterpart("message.error-found"),
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearch = (e) => {
    const filtersValue = e
      ? {
          ...filters,
          [e?.target?.name]: e?.target?.value || "",
        }
      : { search: "", dateRangeStart: "", dateRangeEnd: "" };
    setFilters(filtersValue);
    setTimeout(() => {
      handleGetApplication(1, rowsPerPage, filtersValue);
      updateQueryParams(filtersValue);
    }, 500);
  };

  const handleRemoveFilter = () => {
    handleSearch();
    initParams();
  };

  const handleDetail = (item) => {
    setShowModal(true);
    setItemSelected(item);
  };

  useEffect(() => {
    handleGetApplication(page, rowsPerPage, filters);
  }, [page, rowsPerPage]);

  return (
    <>
      <CustomContainer>
        <Header title={counterpart("menus.validationApply")} />
        <Box mt={2} mb={2}>
          <Filters
            filters={filters}
            handleSearch={handleSearch}
            handleRemoveFilter={handleRemoveFilter}
            showDate
          />
        </Box>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{counterpart("common.firstname")}</TableCell>
                  <TableCell>{counterpart("common.lastname")}</TableCell>
                  <TableCell>
                    {counterpart("common.application-date")}
                  </TableCell>
                  <TableCell>{counterpart("common.role")}</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {applications?.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item?.user?.firstName}</TableCell>
                    <TableCell>{item?.user?.lastName}</TableCell>
                    <TableCell>
                      {moment(item.creationDate).format("DD MMM YYYY")}
                    </TableCell>
                    <TableCell>
                      <strong>{item.subscription}</strong>
                    </TableCell>
                    <TableCell>
                      <ButtonAction
                        item={item}
                        userId={item.user.id}
                        handleOpenConfirm={handleOpenConfirm}
                        handleDetail={handleDetail}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {applications?.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={pageCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Paper>
      </CustomContainer>
      {openConfirm && (
        <ConfirmDialog
          title={counterpart("subscription.reject-candidature")}
          desc={counterpart("subscription.confirm-reject")}
          open={openConfirm}
          handleClose={() => {
            setOpenConfirm(false);
          }}
          handleConfirm={handleRejectCandidate}
          isLoading={loading}
        />
      )}
      {showModal && (
        <ApplicationDetail
          item={itemSelected}
          open={showModal}
          handleClose={() => {
            setShowModal(false);
          }}
        />
      )}
    </>
  );
};
export default ApplicationPage;
