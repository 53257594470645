import axios from "axios";
import { ENDPOINTS } from "../constants/endpoints";
import { deleteCookie } from "../helpers/cookie";
import { handleResponse } from "../helpers/services";
import { setToken } from "../helpers/utils";

class SessionService {
  async get(token) {
    return axios
      .get(`${ENDPOINTS.USER}/Current`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        baseURL: `${process.env.REACT_APP_API_URL}/api`,
      })
      .then(handleResponse);
  }

  async logout() {
    deleteCookie("token");
    setToken(null);
    return { responseData: null, statusText: "OK" };
  }
}

export const sessionService = new SessionService();
