import { createTheme } from "@mui/material/styles";
import { responsiveFontSizes } from "@mui/material";
import shadows from "./shadows";
import { light } from "./palette";

const theme = responsiveFontSizes(
  createTheme({
    palette: light,
    shadows: shadows(),
    typography: {
      fontFamily: '"Inter", sans-serif',
      button: {
        textTransform: "none",
        fontWeight: "medium",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 700,
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            "& th": {
              fontWeight: 600,
            },
          },
        },
      },
    },
  })
);
export default theme;
