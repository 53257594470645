import { ENDPOINTS } from "../constants/endpoints";
import axios from "../helpers/axios";
import { handleResponse } from "../helpers/services";

class UserService {
  async getAllUsers({ page, search = "" }) {
    return axios
      .get(`${ENDPOINTS.USER}?search=${search}&page=${page}&pageSize=50`)
      .then(handleResponse);
  }

  async updateUsers(parameters) {
    return axios
      .put(`${ENDPOINTS.USER}/activate`, parameters)
      .then(handleResponse)
      .then((response) => ({
        responseData: parameters,
        statusText: response.statusText,
      }))
      .catch((error) => error);
  }

  async getUserApplied(parameters, filters) {
    let filterValue = "";
    Object.keys(filters).forEach((key) => {
      if (filters[key]?.length > 0) {
        filterValue += `&${key}=${filters[key]}`;
      }
    });
    return axios
      .get(
        `${ENDPOINTS.USER}/apply?step=2&page=${parameters.page}&pageSize=${parameters.pageSize}${filterValue}`
      )
      .then(handleResponse);
  }
}

export const userService = new UserService();
