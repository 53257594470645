import { ENDPOINTS } from "../constants/endpoints";
import axios from "../helpers/axios";
import { handleResponse } from "../helpers/services";

class AdviceService {
  async getAllAdvices({ page, search = "", processed = true }) {
    return axios
      .get(
        `${ENDPOINTS.ADVICE}?search=${search}&processed=${processed}&page=${page}&pageSize=50`
      )
      .then(handleResponse);
  }

  async getPrescriptions({ page, search = "", processed = true }) {
    return axios
      .get(
        `${ENDPOINTS.PRESCRIPTION}?search=${search}&processed=${processed}&page=${page}&pageSize=50`
      )
      .then(handleResponse);
  }

  async toggleProcess(id, isAdvice) {
    return isAdvice
      ? axios
          .put(`${ENDPOINTS.ADVICE}/${id}/toggle-process`)
          .then(handleResponse)
      : axios
          .put(`${ENDPOINTS.PRESCRIPTION}/${id}/toggle-process`)
          .then(handleResponse);
  }
}

export const adviceService = new AdviceService();
