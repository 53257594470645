import { ROUTES } from "../../constants/routes";

export const listMenus = [
  {
    title: "menus.users",
    to: ROUTES.USERS,
  },
  {
    title: "menus.mediators",
    to: ROUTES.MEDIATORS,
  },
  {
    title: "menus.logout",
    to: ROUTES.LOGOUT,
    alwaysDisplay: false,
    displayForUsers: true,
  },
];
