/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import { useHistory } from "react-router";
import CustomContainer from "../../components/CustomContainer";
import DataTable from "../../components/DataTable";
import Header from "../../components/Header";
import { useCounterpart } from "../../context/language.context";
import { userService } from "../../services/user.service";
import Filters from "../../components/Filters";

const headCells = [
  {
    id: "avatar",
    label: "common.avatar",
    isAvatar: true,
  },
  {
    id: "firstName",
    label: "common.firstname",
  },
  {
    id: "lastName",
    label: "common.lastname",
  },
  {
    id: "email",
    label: "common.email",
  },
  {
    id: "role",
    label: "common.role",
    isRole: true,
  },
  {
    id: "creationDate",
    label: "common.registration-date",
    isDate: true,
  },
];

const UserPage = () => {
  const { counterpart } = useCounterpart();
  const [isInitSelected, setIsInitSelected] = useState(false);
  const [pageInfo, setPageInfo] = useState();
  const [users, setUsers] = useState();
  const router = useHistory();
  const [filters, setFilters] = useState({
    search: "",
  });

  const handleActivateUser = (userIds, activate) => {
    userService.updateUsers({ userIds, activate }).then(() => {
      setUsers(
        users.map((item) => {
          if (userIds?.includes(item.id)) {
            return { ...item, deletionDate: activate ? null : new Date() };
          }
          return item;
        })
      );
    });

    setIsInitSelected(true);
  };

  const handleGetUsers = (pageValue, filterValues) => {
    userService
      .getAllUsers({ page: pageValue, search: filterValues?.search })
      .then((res) => {
        setUsers(res?.responseData?.hits);
        setPageInfo({
          currentPage: pageValue,
          pageSize: res?.responseData?.pageSize,
          pageCount: res?.responseData?.pageCount,
          total: res?.responseData?.totalRowCount,
        });
      });
  };

  const handleChangePage = (e, newPage) => {
    handleGetUsers(newPage + 1, filters);
  };

  const handleSearch = (e) => {
    const filtersValue = { ...filters, search: e?.target?.value || "" };
    setFilters(filtersValue);
    setTimeout(() => {
      handleGetUsers(1, filtersValue);
      router.replace(
        e?.target?.value?.length > 0
          ? `${router?.location?.pathname}?search=${e?.target?.value}`
          : router?.location?.pathname
      );
    }, 500);
  };

  const handleRemoveFilter = () => {
    handleSearch();
  };

  useEffect(() => {
    if (router?.location?.search?.length > 0) {
      const params = new URLSearchParams(router?.location?.search);
      setFilters({ search: params.get("search") });
      handleGetUsers(1, { search: params.get("search") });
    } else {
      handleGetUsers(1, filters);
    }
  }, []);

  return (
    <CustomContainer>
      <Header title={counterpart("menus.users")} />
      <Box mt={2} mb={2}>
        <Filters
          filters={filters}
          handleSearch={handleSearch}
          handleRemoveFilter={handleRemoveFilter}
        />
      </Box>
      <DataTable
        rows={users}
        headCells={headCells}
        onButtonClicked={handleActivateUser}
        isInitSelected={isInitSelected}
        pageInfo={pageInfo}
        handleChangePage={handleChangePage}
        showCheck
      />
    </CustomContainer>
  );
};

export default UserPage;
