import styled from "styled-components";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { theme } from "../../helpers/theme";

export const NavMenuIcon = styled.div`
  margin: 0px 10px;
`;

export const NavMenuSmileyIcon = styled.img`
  margin: 0px 10px;
  width: 15px;
  height: 15px;
  color: ${theme.mainColor};
`;

export const NavMenuLabel = styled.span``;

export const NavMenuAvatar = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  padding: 30px;
  text-align: center;

  .MuiAvatar-root {
    margin: auto;
  }
  a {
    text-decoration: none;
  }
`;

export const NavMenuUsername = styled.div`
  color: ${theme.text};
  font-weight: 600;
  display: inline-block;
  font-size: 18px;
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
`;

export const CustomList = styled(List)`
  span {
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.75;
    text-transform: uppercase;
    color: ${theme.mainColor};
  }
`;

export const CustomListItem = styled(ListItem)`
  span {
    text-align: start;
    padding-left: 10px;
    padding-right: 10px;
  }
  span,
  svg {
    color: white;
  }
`;
