import { combineReducers } from "redux";
import { user } from "./user.reducer";
import { newsletter } from "./newsletter.reducer";
import { snackbar } from "./snackbar.reducer";
import { session } from "./session.reducer";

const rootReducer = combineReducers({
  user,
  newsletter,
  snackbar,
  session,
});

export default rootReducer;
