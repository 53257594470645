export const light = {
  alternate: {
    main: "#061b2a",
    dark: "#edf1f7",
  },
  cardShadow: "rgba(23, 70, 161, .11)",
  mode: "light",
  primary: {
    main: "#53de62",
    light: "#53de62",
    dark: "#8ADE53",
    contrastText: "#1E2439",
  },
  secondary: {
    light: "#ffb74d",
    main: "#53de62",
    dark: "#8ADE53",
    contrastText: "#1E2439",
  },
  info: {
    main: "#061a2a",
  },
  text: {
    primary: "#1e2022",
    secondary: "#677788",
  },
  divider: "rgba(0, 0, 0, 0.12)",
  background: {
    paper: "#ffffff",
    default: "#f8f8f8",
    level2: "#f5f5f5",
    level1: "#ffffff",
    lightGrey: "#f1efef",
  },
};
