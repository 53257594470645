import React from "react";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import { useCounterpart } from "../../../context/language.context";
import { CustomTableRow } from "./style";

const DataTableHead = (props) => {
  const {
    headCells,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    showCheck,
    showAction,
  } = props;
  const { counterpart } = useCounterpart();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <CustomTableRow>
        {showCheck && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": counterpart("common.select-all"),
              }}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {counterpart(headCell.label)}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc"
                    ? counterpart("common.sorted-descending")
                    : counterpart("common.sorted-ascending")}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {showAction && <TableCell />}
      </CustomTableRow>
    </TableHead>
  );
};

DataTableHead.propTypes = {
  headCells: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  showCheck: PropTypes.bool,
  showAction: PropTypes.bool,
};

DataTableHead.defaultProps = {
  showCheck: false,
  showAction: false,
};

export default DataTableHead;
