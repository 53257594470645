/* eslint-disable arrow-body-style */
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Layout from "./components/Layout";
import { ROUTES } from "./constants/routes";
import { history } from "./helpers/history";
// import NewsletterPage from "./pages/NewsletterPage";
import UserPage from "./pages/UserPage";
import MediatorPage from "./components/MediatorPage";
import ApplicationPage from "./components/ApplicationPage";
import AdvicePage from "./components/AdvicePage";
import PrescriptionPage from "./components/PrescriptionPage";

const App = () => {
  return (
    <Router history={history}>
      <Layout>
        <Switch>
          <Route exact path={ROUTES.ROOT} component={UserPage} />
          <Route path={ROUTES.USERS} component={UserPage} />
          {/* <Route path={ROUTES.NEWSLETTER} component={NewsletterPage} /> */}
          <Route exact path={ROUTES.MEDIATORS} component={MediatorPage} />
          <Route exact path={ROUTES.ADVICE} component={AdvicePage} />
          <Route path={ROUTES.PRESCRIPTION} component={PrescriptionPage} />
          <Route
            path={ROUTES.APPLICATION_VALIDATION}
            component={ApplicationPage}
          />
          <Route
            path={ROUTES.LOGIN}
            component={() => {
              window.location.href = process.env.REACT_APP_AUTH_URL;
              return null;
            }}
          />
          <Route path="*">
            <Redirect to={ROUTES.ROOT} />
          </Route>
        </Switch>
      </Layout>
    </Router>
  );
};

export default App;
