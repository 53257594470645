import { NEWSLETTER } from "../constants/actions";

/* eslint-disable default-param-last */
const initialState = {
  newsletters: [],
  newsletter: null,
};

export function newsletter(state = initialState, action) {
  switch (action.type) {
    case NEWSLETTER.GET_ALL:
      return { ...state, newsletters: action.payload };
    case NEWSLETTER.ADD_NEWSLETTER:
      return {
        ...state,
        newsletters: [...state.newsletters, action.payload],
      };
    default:
      return state;
  }
}
