/* eslint-disable arrow-body-style */
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import React, { useState } from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckIcon from "@mui/icons-material/Check";
import { useCounterpart } from "../../../context/language.context";
import { mediatorService } from "../../../services/mediator.service";

const ButtonAction = ({ mediator, handleViewProfile }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isValidate, setIsValidate] = useState(false);
  const { counterpart } = useCounterpart();

  const handleValidateMediator = () => {
    setIsLoading(true);
    mediatorService
      .validateMediator(mediator?.id)
      .then(() => {
        setIsValidate(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Box display="flex" alignItems="center" gap="10px">
      <Button
        onClick={() => handleViewProfile(mediator?.slug)}
        variant="contained"
        color="info"
      >
        <VisibilityIcon sx={{ mr: 1 }} />
        {counterpart("common.see")}
      </Button>
      {!isValidate ? (
        <Button
          onClick={handleValidateMediator}
          variant="contained"
          color="primary"
        >
          {isLoading ? (
            <CircularProgress
              sx={{
                color: "white",
                width: "20px !important",
                height: "20px !important",
              }}
            />
          ) : (
            <>
              <CheckIcon sx={{ mr: 1 }} />
              {counterpart("common.validate")}
            </>
          )}
        </Button>
      ) : (
        <Typography>{counterpart("common.validated")}</Typography>
      )}
    </Box>
  );
};

ButtonAction.propTypes = {
  mediator: PropTypes.shape().isRequired,
  handleViewProfile: PropTypes.func.isRequired,
};

export default ButtonAction;
