import styled from "styled-components";
import Box from "@mui/material/Box";

export const ConnectionState = styled.div`
  position: absolute;
  margin-top: 30px;
  margin-left: 29px;
  background: #65cb65;
  border-radius: 50%;
  width: 10px;
  height: 10px;
`;

export const ContentAvatar = styled(Box)`
  .xl {
    width: 150px !important;
    height: 150px !important;
    font-size: 3rem !important;
  }

  .xs {
    max-width: 35px !important;
    max-height: 35px !important;
    font-size: 1rem !important;
  }

  .lg {
    width: 100px !important;
    height: 100px !important;
    font-size: 2rem !important;
  }

  .md {
    width: 50px !important;
    height: 50px !important;
    font-size: 1.3rem !important;
  }
`;
