export const USER = {
  GET_ALL: "user.getAll",
  UPDATE_USER: "user.update",
};

export const SNACKBAR = {
  INIT: "snackbar.init",
  UPDATE: "snackbar.update",
};

export const NEWSLETTER = {
  GET_ALL: "newsletter.getAll",
  ADD_NEWSLETTER: "newsletter.add",
};

export const SESSION = {
  GET: "session.get",
  LOGOUT: "session.logout",
};
