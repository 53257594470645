/* eslint-disable arrow-body-style */
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import React, { useState } from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { subscriptionService } from "../../../services/subscription.service";
import { useCounterpart } from "../../../context/language.context";

const ButtonAction = ({ item, userId, handleOpenConfirm, handleDetail }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isValidate, setIsValidate] = useState(false);
  const { counterpart } = useCounterpart();

  const handleValidate = () => {
    setIsLoading(true);
    subscriptionService
      .validateCanditate({ userId })
      .then(() => {
        setIsValidate(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Box display="flex" alignItems="center" gap="10px">
      <Button variant="outlined" onClick={() => handleDetail(item)}>
        {counterpart("common.showDetail")}
      </Button>
      {!isValidate ? (
        <Button onClick={handleValidate} variant="contained" color="primary">
          {isLoading ? (
            <CircularProgress
              sx={{
                color: "white",
                width: "20px !important",
                height: "20px !important",
              }}
            />
          ) : (
            counterpart("common.validate")
          )}
        </Button>
      ) : (
        <Box display="flex" alignItems="center" gap="5px">
          <CheckIcon />
          <Typography>{counterpart("common.validated")}</Typography>
        </Box>
      )}
      <Button
        onClick={() => handleOpenConfirm(userId)}
        variant="contained"
        color="error"
        disabled={isLoading || isValidate}
      >
        <ClearIcon />
        {counterpart("common.decline")}
      </Button>
    </Box>
  );
};

ButtonAction.propTypes = {
  item: PropTypes.shape().isRequired,
  userId: PropTypes.number.isRequired,
  handleOpenConfirm: PropTypes.func.isRequired,
  handleDetail: PropTypes.func.isRequired,
};

export default ButtonAction;
