import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { useHistory } from "react-router";
import { useCounterpart } from "../../context/language.context";
import CustomContainer from "../CustomContainer";
import Header from "../Header";
import { adviceService } from "../../services/advice.service";
import DataTable from "../DataTable";
import AdviceDetail from "./AdviceDetail";
import Filters from "../Filters";

const headCells = [
  {
    id: "firstName",
    label: "common.firstname",
  },
  {
    id: "lastName",
    label: "common.lastname",
  },
  {
    id: "email",
    label: "common.email",
  },
  {
    id: "conflictDescription",
    label: "common.message",
    maxLength: 20,
  },
  {
    id: "creationDate",
    label: "common.registration-date",
    isDate: true,
  },
];

const AdvicePage = ({ type }) => {
  const { counterpart } = useCounterpart();
  const [advices, setAdvices] = useState();
  const [pageInfo, setPageInfo] = useState();
  const [itemSelected, setItemSelected] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [loadingToggle, setLoadingToggle] = useState();
  const router = useHistory();
  const [filters, setFilters] = useState({
    search: "",
    processed: true,
  });

  const handleClick = (item) => {
    setItemSelected(item);
    setOpenDialog(true);
  };

  const handleGetAdvices = (pageValue, filtersValue) => {
    if (type === "advice") {
      adviceService
        .getAllAdvices({
          page: pageValue,
          search: filtersValue?.search,
          processed: filtersValue?.processed,
        })
        .then((res) => {
          setAdvices(res?.responseData?.hits);
          setPageInfo({
            currentPage: pageValue,
            pageSize: res?.responseData?.pageSize,
            pageCount: res?.responseData?.pageCount,
            total: res?.responseData?.totalRowCount,
          });
        });
    } else {
      adviceService
        .getPrescriptions({
          page: pageValue,
          search: filtersValue?.search,
          processed: filtersValue?.processed,
        })
        .then((res) => {
          setAdvices(res?.responseData?.hits);
          setPageInfo({
            currentPage: pageValue,
            pageSize: res?.responseData?.pageSize,
            pageCount: res?.responseData?.pageCount,
            total: res?.responseData?.totalRowCount,
          });
        });
    }
  };

  const handleChangePage = (e, newPage) => {
    handleGetAdvices(newPage + 1, filters);
  };

  const handleSearch = (e) => {
    const filtersValue = {
      ...filters,
      search:
        e?.target?.name === "search" ? e?.target?.value || "" : filters?.search,
      processed:
        e?.target?.name !== "search" ? e?.target?.checked : filters?.processed,
    };
    setFilters(filtersValue);
    router.replace(
      filtersValue?.search?.length > 0
        ? `${router?.location?.pathname}?search=${e?.target?.value}&processed=${filtersValue?.processed}`
        : `${router?.location?.pathname}?processed=${filtersValue?.processed}`
    );
    setTimeout(() => {
      handleGetAdvices(1, filtersValue);
    }, 500);
  };

  const handleRemoveFilter = () => {
    handleSearch();
  };

  const handleToggle = (e, id) => {
    if (e.target.checked) {
      setLoadingToggle({ loading: true, idSelected: id });
      const adviceClone = [...advices];
      const index = adviceClone.findIndex((item) => item.id === id);
      adviceService
        .toggleProcess(id)
        .then(() => {
          adviceClone[index] = {
            ...adviceClone[index],
            processingDate: new Date(),
          };
          setAdvices(adviceClone);
        })
        .catch(() => {
          adviceClone[index] = {
            ...adviceClone[index],
            processingDate: null,
          };
          setAdvices(adviceClone);
        })
        .finally(() => {
          setLoadingToggle({ loading: false, idSelected: id });
        });
    }
  };

  useEffect(() => {
    handleGetAdvices(1, filters);
  }, []);

  return (
    <CustomContainer>
      <Header
        title={
          type === "advice"
            ? counterpart("menus.advice")
            : counterpart("menus.advicePrescription")
        }
      />
      <Box>
        <Filters
          filters={filters}
          handleSearch={handleSearch}
          handleRemoveFilter={handleRemoveFilter}
          showProcessed
        />
      </Box>
      <DataTable
        rows={advices}
        headCells={headCells}
        pageInfo={pageInfo}
        handleChangePage={handleChangePage}
        handleDetail={handleClick}
        handleToggle={handleToggle}
        loadingToggle={loadingToggle}
      />
      {openDialog && (
        <AdviceDetail
          advice={itemSelected}
          open={openDialog}
          handleClose={() => {
            setOpenDialog(false);
          }}
          type={type}
        />
      )}
    </CustomContainer>
  );
};

AdvicePage.propTypes = {
  type: PropTypes.string,
};

AdvicePage.defaultProps = {
  type: "advice",
};

export default AdvicePage;
