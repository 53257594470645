/* eslint-disable arrow-body-style */
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import React from "react";
import { useCounterpart } from "../../context/language.context";

const ConfirmDialog = ({
  title,
  desc,
  open,
  handleConfirm,
  handleClose,
  isLoading,
}) => {
  const { counterpart } = useCounterpart();
  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{desc}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={!isLoading ? handleConfirm : null} variant="contained">
          {isLoading ? (
            <CircularProgress
              sx={{
                color: "white",
                width: "24px !important",
                height: "24px !important",
              }}
            />
          ) : (
            counterpart("common.confirm")
          )}
        </Button>
        <Button onClick={!isLoading ? handleClose : null} variant="outlined">
          {counterpart("common.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

ConfirmDialog.defaultProps = {
  isLoading: false,
};

export default ConfirmDialog;
