import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useHistory } from "react-router";
import { theme } from "../../helpers/theme";

const NavBarMenu = ({ menu, childMenu, frame }) => {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickRoute = (route, isBlank) => {
    if (route.length) {
      if (isBlank) {
        const newWindow = window.open(route, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
      } else {
        history.push(route);
        setAnchorEl(null);
      }
    }
  };
  return (
    <>
      <Button
        size="medium"
        variant={frame ? "outlined" : "text"}
        onClick={(e) => {
          if (menu.route) {
            handleClickRoute(menu.route);
          } else if (childMenu && childMenu.length) {
            handleClick(e);
          }
        }}
        color={frame ? "secondary" : "inherit"}
        sx={{
          // color: frame ? "#8ADE53" : "white",
          fontWeight: frame ? "bold" : "600 !important",
          backgroundColor: "transparent",
          fontFamily: "'Outfit', sans-serif",
          cursor: "pointer",
          fontSize: "1rem",
          textTransform: "none",
          // borderColor: frame ? "#8ADE53" : "transparent",
        }}
        endIcon={
          !!childMenu?.length &&
          (open ? <ExpandLessIcon /> : <ExpandMoreIcon />)
        }
      >
        {menu.label}
      </Button>
      {childMenu && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          PaperProps={{
            sx: {
              bgcolor: theme.pastelGreen,
            },
          }}
        >
          {childMenu.map((m) => (
            <MenuItem
              key={m.label}
              disabled={m.disabled}
              onClick={() => {
                handleClickRoute(m.route, m?.isBlank);
                setAnchorEl(null);
              }}
              sx={{
                fontSize: "0.95rem",
                fontWeight: "500",
              }}
            >
              {m.label}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

NavBarMenu.propTypes = {
  menu: PropTypes.shape({
    label: PropTypes.string.isRequired,
    route: PropTypes.string,
  }).isRequired,
  childMenu: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      route: PropTypes.string,
    }).isRequired
  ),
  frame: PropTypes.bool,
};

NavBarMenu.defaultProps = {
  childMenu: null,
  frame: false,
};

export default NavBarMenu;
