/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
import React from "react";
import Toolbar from "@mui/material/Toolbar";
import { alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import BlockIcon from "@mui/icons-material/Block";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useCounterpart } from "../../../context/language.context";

const DataTableToolbar = (props) => {
  const { counterpart } = useCounterpart();
  const { selected, onButtonClicked } = props;

  return (
    selected.length > 0 && (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(selected.length > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {`${selected.length} ${counterpart("common.selected")}`}
        </Typography>
        <Tooltip title={counterpart("common.enable")}>
          <IconButton
            onClick={
              onButtonClicked
                ? () => {
                    onButtonClicked(selected, true);
                  }
                : null
            }
          >
            <CheckCircleOutlineIcon sx={{ fontSize: "2rem", fill: "green" }} />
          </IconButton>
        </Tooltip>
        <Tooltip title={counterpart("common.disable")}>
          <IconButton
            // onClick={() => {
            //   onButtonClicked(selected, false);
            // setIsRemoveModalOpen(true);
            // }}
            onClick={
              onButtonClicked
                ? () => {
                    onButtonClicked(selected, false);
                  }
                : null
            }
          >
            <BlockIcon sx={{ fontSize: "2rem" }} />
          </IconButton>
        </Tooltip>
      </Toolbar>
    )
  );
};

DataTableToolbar.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  onButtonClicked: PropTypes.func,
  setIsRemoveModalOpen: PropTypes.func,
};

DataTableToolbar.defaultProps = {
  onButtonClicked: null,
  setIsRemoveModalOpen: null,
};

export default DataTableToolbar;
