/* eslint-disable no-unused-vars */
import React from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import ListItemText from "@mui/material/ListItemText";
import { ROUTES } from "../../constants/routes";
import { useCounterpart } from "../../context/language.context";
import CustomAvatar from "../CustomAvatar";
import {
  CustomList,
  CustomListItem,
  NavMenuAvatar,
  NavMenuUsername,
} from "./style";
import { listMenus } from "./listMenus";
import { theme } from "../../helpers/theme";

const NavMenu = ({ isMenuOpen, closeMenuOpen, user }) => {
  const { counterpart } = useCounterpart();
  const history = useHistory();

  const goToMenu = (link) => () => {
    console.log("heeerereee");
    // if (link === "/logout") {
    // } else {
    //   closeMenuOpen();
    //   history.push(link);
    // }
  };

  return (
    <Drawer
      anchor="right"
      open={isMenuOpen}
      onClose={closeMenuOpen}
      PaperProps={{
        sx: {
          backgroundColor: theme.blackPearl,
        },
      }}
    >
      <Box sx={{ width: 280 }} role="presentation">
        <NavMenuAvatar>
          <Link to={ROUTES.ACCOUNT} onClick={closeMenuOpen}>
            <NavMenuUsername>
              {user ? user.firstName : counterpart("menus.guest")}
            </NavMenuUsername>
            <CustomAvatar user={user} size="lg" />
          </Link>
        </NavMenuAvatar>
        <CustomList>
          {listMenus.map((menu) => (
            <CustomListItem button onClick={goToMenu(menu.to)} key={menu.title}>
              <ListItemText primary={counterpart(menu.title)} />
            </CustomListItem>
          ))}
        </CustomList>
      </Box>
    </Drawer>
  );
};

NavMenu.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  closeMenuOpen: PropTypes.func.isRequired,
  user: PropTypes.shape(),
};

NavMenu.defaultProps = {
  user: null,
};

export default NavMenu;
