import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import NavBar from "../NavBar";
import NavMenu from "../NavMenu";
import { LayoutContainer } from "./style";
import { useSession } from "../../context/session.context";

const Layout = ({ children }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { user, isLoading } = useSession();

  const toggleMenuOpen = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenuOpen = () => {
    if (isMenuOpen) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [isMenuOpen]);

  return isLoading ? (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress />
    </Box>
  ) : (
    <LayoutContainer>
      <NavBar user={user} setMenuOpen={toggleMenuOpen} />
      {children}
      <NavMenu
        isMenuOpen={isMenuOpen}
        closeMenuOpen={closeMenuOpen}
        user={user}
      />
    </LayoutContainer>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
