import { ENDPOINTS } from "../constants/endpoints";
import axios from "../helpers/axios";
import { handleResponse } from "../helpers/services";

class SubscriptionService {
  async validateCanditate(parameters) {
    return axios
      .post(`${ENDPOINTS.SUBSCRIPTION}/validate-candidate`, parameters)
      .then(handleResponse);
  }

  async rejectCandidate(parameters) {
    return axios
      .post(`${ENDPOINTS.SUBSCRIPTION}/reject-candidate`, parameters)
      .then(handleResponse);
  }
}

export const subscriptionService = new SubscriptionService();
