/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React, { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import { useCounterpart } from "../../../context/language.context";
import axios from "../../../helpers/axios";

const headCells = [
  {
    id: "firstName",
    label: "common.firstname",
  },
  {
    id: "lastName",
    label: "common.lastname",
  },
  {
    id: "email",
    label: "common.email",
  },
  {
    id: "phoneNumber",
    label: "common.phone",
  },
  {
    id: "conflictDescription",
    label: "common.message",
  },
];

const AdviceDetail = ({ advice, open, handleClose, type }) => {
  const { counterpart } = useCounterpart();
  const [loading, setLoading] = useState(false);

  const handleDownload = () => {
    setLoading(true);
    axios
      .get(advice.fileUrl, {
        responseType: "blob",
      })
      .then((res) => {
        const blob = new Blob([res.data], {
          type: res.headers["content-type"],
        });

        const blobUrl = URL.createObjectURL(blob);

        const downloadLink = document.createElement("a");
        downloadLink.href = blobUrl;

        const contentDispositionHeader = res.headers["content-disposition"];
        const filenameMatch =
          contentDispositionHeader &&
          contentDispositionHeader.match(/filename="([^"]+)"/);
        const suggestedFilename = filenameMatch
          ? filenameMatch[1]
          : "downloaded-file";

        downloadLink.download = suggestedFilename;

        downloadLink.style.display = "none";

        document.body.appendChild(downloadLink);
        downloadLink.click();

        // Clean up: remove the link and revoke the Blob URL
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(blobUrl);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Information</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {headCells.map((item) => (
            <React.Fragment key={item.id}>
              <Grid item xs={2}>
                <Typography variant="body2" fontWeight={600}>
                  {counterpart(item.label)}:
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography>{advice?.[item.id] || "-"}</Typography>
              </Grid>
            </React.Fragment>
          ))}
          <Grid item xs={2}>
            <Typography variant="body2" fontWeight={600}>
              {counterpart("common.registration-date")}:
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography>
              {moment(new Date(advice?.creationDate)).format("DD MMMM YYYY")}
            </Typography>
          </Grid>
          {type === "prescription" && advice?.fileUrl && (
            <>
              <Grid item xs={12}>
                <Typography variant="body2" fontWeight={600}>
                  Document:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  onClick={!loading ? handleDownload : null}
                >
                  {loading ? (
                    <CircularProgress
                      sx={{
                        width: "25px !important",
                        height: "25px !important",
                        color: "white !important",
                      }}
                    />
                  ) : (
                    counterpart("common.download")
                  )}
                </Button>
                {/* <Typography>{`${process.env.REACT_APP_API_URL}${advice.fileUrl}`}</Typography> */}
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          {counterpart("common.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AdviceDetail.propTypes = {
  advice: PropTypes.shape().isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default AdviceDetail;
