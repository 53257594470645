import { ENDPOINTS } from "../constants/endpoints";
import axios from "../helpers/axios";
import { handleResponse } from "../helpers/services";

class MediatorService {
  async getAllMediatorsToValidate(parameters, filters) {
    let filterValue = "";
    Object.keys(filters).forEach((key) => {
      if (filters[key]?.length > 0) {
        filterValue += `&${key}=${filters[key]}`;
      }
    });
    return axios
      .get(
        `${ENDPOINTS.MEDIATOR}/to-validate?page=${parameters?.page}&pageSize=${parameters?.pageSize}${filterValue}`
      )
      .then(handleResponse);
  }

  async validateMediator(id) {
    return axios.post(`${ENDPOINTS.MEDIATOR}/${id}/validate`, null);
  }
}

export const mediatorService = new MediatorService();
