import React from "react";
import Avatar from "@mui/material/Avatar";
import PropTypes from "prop-types";
import { useCounterpart } from "../../context/language.context";
import { ConnectionState, ContentAvatar } from "./style";

const CustomAvatar = ({ user, className, showConnectionState, size }) => {
  const { avatar, firstName, lastName } = user || {};
  const { counterpart } = useCounterpart();

  const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const renderInitials = () =>
    user && !user.deletionDate ? (
      `${firstName && firstName[0]}${lastName && lastName[0]}`.toUpperCase()
    ) : (
      <i className="fas fa-user" />
    );

  const stringAvatar = () => ({
    sx: {
      bgcolor:
        user && !user.deletionDate
          ? !avatar && stringToColor(`${firstName} ${lastName}`)
          : "#377dff",
    },
    children: renderInitials(),
  });

  const title =
    user && !user.deletionDate
      ? `${firstName} ${lastName}`
      : counterpart("common.deleted-user");

  return (
    <ContentAvatar>
      <Avatar
        title={title}
        alt={title}
        src={avatar ? `${process.env.REACT_APP_API_URL}/${avatar}` : null}
        className={`${className} ${size}`}
        {...stringAvatar()}
      >
        {renderInitials()}
      </Avatar>
      {showConnectionState && <ConnectionState />}
    </ContentAvatar>
  );
};

CustomAvatar.propTypes = {
  user: PropTypes.shape(),
  className: PropTypes.string,
  size: PropTypes.string,
  showConnectionState: PropTypes.bool,
};

CustomAvatar.defaultProps = {
  user: undefined,
  className: "",
  size: "md",
  showConnectionState: false,
};

export default CustomAvatar;
